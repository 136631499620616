import { makeStyles, withStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
// import MenuItem from "@material-ui/core/MenuItem";
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { useTheme } from '@material-ui/styles'
import { useEditableProps } from '@sb-konzept/gatsby-source-storyblok'
import Link from 'components/Link'
// import styled from "styled-components";
import Image from 'gatsby-image'
import React, { useCallback, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  flexToolbar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  subMenu: {
    width: '100%'
  },
  subMenuItem: {
    paddingLeft: '16px'
  }
}))

const StyledMenu = withStyles((theme) => ({
  paper: {
    border: 'none',
    backgroundColor: 'white'
  }
}))((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    display: 'block'
    // textAlign: "center"
  }
}))(Button)

const createLink = (item, align) => (
  <StyledMenuItem key={item.id || item._uid} component={Link} link={item.link}>
    <Box align={align}>{item.label}</Box>
  </StyledMenuItem>
)

function NavigationHeader(props) {
  const { content } = props
  const { items, logo, logoAltText, logoLink, title, titleAltText } = content
  const editableProps = useEditableProps(content)
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const hideDrawer = useCallback(() => {
    setOpen(false)
  }, [])
  const showDrawer = useCallback(() => {
    setOpen(true)
  }, [])
  const [anchorEl, setAnchorEl] = useState(null)
  const [secAnchorEl, setSecAnchorEl] = useState(null)
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleClick = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleHover = (event, num) => {
    num === 0
      ? setAnchorEl(event.currentTarget)
      : setSecAnchorEl(event.currentTarget)
  }

  const handleClose = (a, b) => {
    b && setSecAnchorEl(null)
    a && setAnchorEl(null)
  }

  return (
    <>
      <Drawer anchor="right" open={open} onClose={hideDrawer}>
        <div role="button" tabIndex={0}>
          <Box width={250}>
            <List>
              {items.map((item) =>
                !item.childLinks ? (
                  <ListItem
                    key={item.id || item._uid}
                    button
                    component={Link}
                    link={item.link}
                    onClick={hideDrawer}
                  >
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{ variant: 'body2' }}
                    />
                  </ListItem>
                ) : (
                  // Top-level item and nested container
                  <List key={item.id || item._uid}>
                    <ListItem button onClick={handleClick}>
                      <ListItemText
                        primary={item.label}
                        primaryTypographyProps={{ variant: 'body2' }}
                      />
                    </ListItem>

                    <Collapse unmountOnExit in={mobileOpen} timeout="auto">
                      <ListItem disablePadding>
                        <List className={classes.subMenu}>
                          {item.childLinks.map((childLink) =>
                            childLink.link.id ? (
                              <ListItem
                                key={childLink.id || childLink._uid}
                                button
                                className={classes.subMenuItem}
                                component={Link}
                                link={childLink.link}
                                onClick={hideDrawer}
                              >
                                <ListItemText
                                  primary={childLink.label}
                                  primaryTypographyProps={{
                                    variant: 'body2'
                                  }}
                                />
                              </ListItem>
                            ) : (
                              <ListItem
                                key={childLink.id || childLink._uid}
                                button
                              >
                                <ListItemText
                                  primary={childLink.label}
                                  primaryTypographyProps={{
                                    variant: 'body2'
                                  }}
                                />
                              </ListItem>
                            )
                          )}
                        </List>
                      </ListItem>
                    </Collapse>
                  </List>
                )
              )}
            </List>
          </Box>
        </div>
      </Drawer>

      <AppBar color="secondary" position="sticky">
        {/* BOX below => maxWidth={theme.breakpoints.width("xl")} */}
        <Box mx="auto" width="100%">
          <Toolbar disableGutters>
            <Box>
              <Link link={logoLink}>
                {logo.localFile.childImageSharp ? (
                  <Box bgcolor="secondary.main" px={3} py={1}>
                    <Image
                      alt={logoAltText || 'image'}
                      fixed={logo.localFile.childImageSharp?.fixed}
                    />
                  </Box>
                ) : (
                  <Box as="img" height={104} src={logo.localFile.publicURL} />
                )}
              </Link>
            </Box>

            <Box
              alignItems="stretch"
              color={theme.palette.text.inactive}
              display="flex"
              flexDirection="column"
              flexGrow="1"
            >
              <Box
                boxShadow={24}
                display="flex"
                flexGrow="1"
                justifyContent="flex-end"
              >
                <Hidden mdUp initialWidth="md">
                  <Box textAlign="right" width="100%">
                    <IconButton
                      aria-label="Menu"
                      color="inherit"
                      onClick={showDrawer}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                </Hidden>
                <Hidden smDown initialWidth="md">
                  <Toolbar>
                    {items.map((item, index) =>
                      !item.childLinks ? (
                        createLink(item, 'center')
                      ) : (
                        <Button
                          key={item.id || item._uid}
                          // onMouseEnter={e => handleHover(e, 0)} // for hover => doesn't work as nicely as CSS hovers
                          onClick={
                            // for click => handles fine, but still not as good as CSS menus
                            anchorEl
                              ? () => handleClose(false, false)
                              : (e) => handleHover(e, 0)
                          }
                        >
                          {item.label}
                          <StyledMenu
                            keepMounted
                            anchorEl={anchorEl}
                            MenuListProps={{
                              onMouseLeave: () => handleClose(true, true)
                            }}
                            open={Boolean(anchorEl)}
                          >
                            {item.childLinks.map(
                              (item, index) =>
                                // !item.childLinks ? (
                                createLink(item, 'left')
                              // Unused code for extra submenu; doesn't work as smoothly as CSS menus
                              // ) : (
                              //   <Button
                              //     key={item.id || item._uid}
                              //     onMouseEnter={e => handleHover(e, 1)}
                              //     component={Link}
                              //     link={item.link}
                              //     //   secAnchorEl
                              //     //     ? handleClose
                              //     //     : e => handleHover(e, 1)
                              //     // }
                              //   >
                              //     {item.label}
                              //     <StyledMenu
                              //       anchorEl={secAnchorEl}
                              //       keepMounted
                              //       open={Boolean(secAnchorEl)}
                              //       // onClose={handleClose}
                              //       MenuListProps={{
                              //         onMouseLeave: () =>
                              //           handleClose(false, true)
                              //       }}
                              //     >
                              //       {item.childLinks.map((item, index) =>
                              //         item.link.id ? (
                              //           createLink(item, "left")
                              //         ) : (
                              //           <div>NO MORE ITEMS</div>
                              //         )
                              //       )}
                              //     </StyledMenu>
                              //   </Button>
                              // )
                            )}
                          </StyledMenu>
                        </Button>
                      )
                    )}
                  </Toolbar>
                </Hidden>
              </Box>

              <Box display="flex" flexGrow="1" justifyContent="flex-end">
                <Toolbar className={classes.flexToolbar}>
                  <Box
                    flex={1}
                    maxWidth={541}
                    p={{ xs: 0, sm: 1 }}
                    position="relative"
                  >
                    <Image
                      alt={titleAltText || 'image'}
                      fluid={title.localFile.childImageSharp?.fluid}
                    />
                  </Box>
                </Toolbar>
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    </>
  )
}

export default NavigationHeader
