// import { useEditableProps } from "@sb-konzept/gatsby-source-storyblok";
import Button from '@material-ui/core/Button'
// import Typography from "@material-ui/core/Typography";
import Box from 'components/Box'
import DatenschutzModal from 'components/DatenschutzModal'
import LinkComponent from 'components/Link'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'
import ActionButton from 'storyblok-components/Button'
import styled from 'styled-components'

import WhiteRightBanner from '../../assets/images/banner-right-white.svg'

const EmailInput = styled.input`
  display: block;
  padding: 4px 12px;
  width: max-content;
  font-family: 'Open sans', sans-serif;
  border: 1px solid #eaeaea;

  ::placeholder {
    font-weight: 400;
  }
`

const StyledLink = styled(Link)`
  margin: inherit;
  padding: 6px 0;
  display: block;
  width: max-content;
  text-decoration: none;
  font-size: 0.88rem;
  font-weight: 400;
  white-space: nowrap;
  transition: color 200ms;
  color: inherit;

  :hover {
    color: white;
  }
`

const TrapezoidBadge = styled(Box)`
  background-image: url(${WhiteRightBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;

  font-weight: 700;
  text-transform: uppercase;
`

function Footer(props) {
  const { content } = props
  // const editableProps = useEditableProps(content);
  const gatsbyData = useStaticQuery(graphql`
    {
      TwinsWhiteLogo: file(name: { eq: "twin-tae-white" }) {
        childImageSharp {
          fluid(fit: CONTAIN) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      {/* Foundations of Taekwondo */}
      {/* <Box m="auto" px={3} mt={2} mb={1}>
        <Typography
          align="center"
          // variant="body1"
          variant="h5"
          component="p"
          color="text.primary"
        >
          <b>
            HÖFLICHKEIT - INTEGRITÄT - DURCHHALTEVERMÖGEN - SELBSTDISZIPLIN -
            UNBEZWINGLICHKEIT
          </b>
        </Typography>
      </Box> */}

      <Box
        as="footer"
        bgcolor="tertiary.main"
        display="flex"
        flexDirection="column"
        textAlign={{ xs: 'center', sm: 'left' }}
      >
        {/* Black footer */}
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          mx="8.33vw"
          // pb={2}
        >
          <Box flex={1} my={3}>
            <Box maxWidth={{ xs: '100%', md: '80%' }}>
              <Box as={Link} to="/">
                <Image
                  fluid={gatsbyData.TwinsWhiteLogo?.childImageSharp.fluid}
                />
              </Box>
            </Box>
            <Box color="text.inactive" fontSize="0.88rem" mt={1}>
              Die neue Art der alten Kunst
            </Box>
          </Box>

          <Box
            color="white"
            display="flex"
            flex={1}
            flexDirection={{ xs: 'column', sm: 'row' }} // "text.inactive"
            px={4}
            py={2}
          >
            <Box display="flex" flex={1} justifyContent="center">
              <Box
                flexDirection="column"
                margin={{ xs: 'auto', sm: 'initial' }}
              >
                <StyledLink to="/ueber-uns">Über Uns</StyledLink>
                <StyledLink to="/philosophie">Philosophie</StyledLink>
                {/* <StyledLink to="/fuer-wen">Für Wen</StyledLink> */}
                <StyledLink to="/lehrsystem">Lehrsystem</StyledLink>
              </Box>
            </Box>
            <Box display="flex" flex={1} justifyContent="center">
              <Box
                flexDirection="column"
                margin={{ xs: 'auto', sm: 'initial' }}
              >
                <StyledLink to="/impressionen">Fotos / Videos</StyledLink>
                <StyledLink to="/camps">Camps</StyledLink>
                <StyledLink to="/news">News</StyledLink>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flex={1}
            justifyContent={{ xs: 'center', sm: 'flex-end' }}
          >
            <Box display="flex" flexDirection="column" my={1.5}>
              <Box color="text.secondary" fontSize="0.88rem" my={1}>
                Immer informiert über unsere Events!
              </Box>
              <Box m={{ xs: 'auto', sm: 'initial' }}>
                <EmailInput placeholder="Ihre E-mail Adresse" type="email" />
              </Box>

              <Box mt={0.5}>
                <label
                  htmlFor="acceptGDPR"
                  style={{ display: 'block', paddingLeft: 15, textIndent: -15 }}
                >
                  <input
                    name="acceptGDPR"
                    style={{
                      margin: 0,
                      marginRight: 2,
                      verticalAlign: 'middle'
                      // position: "relative",
                    }}
                    type="checkbox"
                  />
                  <DatenschutzModal />
                </label>
              </Box>

              <Box my={2}>
                <ActionButton
                  content={{
                    label: 'SENDEN',
                    variant: 'contained',
                    backgroundColor: 'primary.main',
                    color: 'primary'
                  }}
                  // TODO: Set up mailchimp !
                  onClick={() => console.log('Setup MailChimp')}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* White footer */}
        <TrapezoidBadge
          alignSelf="flex-end"
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent="center"
          pl={{ xs: 0, sm: '52vw' }}
          pr={{ xs: 0, sm: '9vw' }}
          py={2}
          width={{ xs: '100vw', sm: 'max-content' }}
        >
          <Box px="1vw">
            <Button disabled to="/">
              © {new Date().getFullYear()}
            </Button>
          </Box>
          {content.items.map((item) => (
            <Box key={item.id || item._uid} px="1vw">
              <Button
                color="inherit"
                component={LinkComponent}
                link={item.link}
              >
                {item.label}
              </Button>
            </Box>
          ))}
        </TrapezoidBadge>
      </Box>
    </>
  )
}

export default Footer
