import React, { useCallback, useState, useMemo } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Image from "gatsby-image";
import { useTheme, makeStyles } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";
// import { sortBy } from "ramda";
// import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  toggle: {
    cursor: "pointer",
    transform: "translateY(-47%)"
  },
  verticalText: {
    textOrientation: "upright",
    fontWeight: 700,
    writingMode: "vertical-rl",
    zIndex: 10001,

    // ms-ie/edge fix => but doesn't orient letters upright
    "-ms-writing-mode": "tb-rl",
    "-webkit-writing-mode": "tb-rl",
    "*writing-mode": "tb-rl",

    "-ms-text-orientation": "upright",
    "-webkit-text-orientation": "upright",
    "*text-orientation": "upright",

    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem"
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.1rem"
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.66rem"
    }
  },
  rotate: {
    transform: "rotate(-15deg)"
  }
}));

// Inside of sidebar
const DojoList = props => (
  <List>
    {/* First two are the main Standorte */}
    <ListItem button component={Link} to="/muenchen-zentrum">
      <ListItemText
        primary="ZENTRALE MÜNCHEN"
        primaryTypographyProps={{ variant: "body2" }}
      />
    </ListItem>
    <ListItem button component={Link} to="/zentrale-augsburg">
      <ListItemText
        primary="ZENTRALE AUGSBURG"
        primaryTypographyProps={{ variant: "body2" }}
      />
    </ListItem>

    {props.dojos.map(dojo => {
      const {
        node: {
          full_slug,
          uuid,
          id,
          content: { location }
        }
      } = dojo;
      const slug = full_slug.match(/\/.*\//)[0];

      // console.log(dojo);

      return (
        location !== "Template" && (
          <ListItem button key={id || uuid} component={Link} to={slug}>
            <ListItemText
              primary={location.toUpperCase()}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </ListItem>
        )
      );
    })}
  </List>
);

// Sidebar parent with open/close and image
function Sidebar() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const hideDrawer = useCallback(() => {
    setOpen(false);
  }, []);
  const showDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        {
          sidebarImage: file(name: { eq: "sidebar" }) {
            id
            name
            childImageSharp {
              fluid(maxWidth: 163) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          allStoryblokDojoSiteInfoStory {
            edges {
              node {
                content {
                  location
                  menuOrder
                }
                full_slug
                uuid
                id
              }
            }
          }
        }
      `}
      render={props => {
        const { sidebarImage, allStoryblokDojoSiteInfoStory } = props;
        const allDojos = allStoryblokDojoSiteInfoStory.edges
          .filter(
            dojo =>
              [
                "Kopieren Sie diese Vorlage",
                "Zentrale München",
                "Zentrale Augsburg",
                "Example"
              ].indexOf(dojo.node.content.location) < 0
          )
          .sort((a, b) => {
            const locA = a.node.content.location.toUpperCase();
            const locB = b.node.content.location.toUpperCase();
            return locA < locB ? -1 : locA > locB ? 1 : 0;
          }); // sort by location name
        // .sort((a, b) => a.node.content.menuOrder - b.node.content.menuOrder); // by menu order

        // console.log(allDojos);

        return (
          <>
            {sidebarImage && (
              // Main card
              <Box
                position="fixed"
                top="62vh"
                // bottom="2vh"
                left={0}
                width={{ xs: 35, sm: 57.5, md: 70 }}
                height="auto"
                zIndex={1010}
                className={classes.toggle}
                onClick={() => showDrawer()}
              >
                {/* Main text */}
                {/* <Box
                  position="absolute"
                  left="22%"
                  width="max-content"
                  top="6%"
                  color="text.secondary"
                  className={classes.verticalText}
                >
                  STANDORTE
                </Box> */}

                {/* Counter and yellow card */}
                {/* <Box
                  position="absolute"
                  bgcolor="primary.main"
                  border="2px solid black"
                  height="19%"
                  width="85%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={100}
                  fontSize={{ xs: "1.12rem", sm: "1.6rem", md: "2.2rem" }}
                  left="85%"
                  top="6%"
                  fontWeight={700}
                  fontFamily='"futura-pt", "Open Sans", sans-serif'
                  color="black"
                  className={classes.rotate}
                >
                  {allDojos.length + 2}
                </Box> */}
                <Image fluid={sidebarImage.childImageSharp.fluid} />
              </Box>
            )}

            {/* Drawer inner */}
            <Drawer anchor="left" open={open} onClose={hideDrawer}>
              <div tabIndex={0} role="button" onClick={hideDrawer}>
                <Box width={250}>
                  <DojoList dojos={allDojos} />
                </Box>
              </div>
            </Drawer>
          </>
        );
      }}
    />
  );
}

export default Sidebar;
