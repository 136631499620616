import React from "react";
import Box from "components/Box";
// import Sidebar from "components/Sidebar";

function Layout(props) {
  const { children } = props;

  // console.log("Layout children:", children);

  return (
    <>
      <Box as="main" css="overflow-x: hidden;" bgcolor="background">
        {/* <Sidebar /> */}

        {children}
      </Box>
    </>
  );
}

export default Layout;
