// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-sb-konzept-gatsby-source-storyblok-src-components-storyblok-preview-index-js": () => import("./../../../node_modules/@sb-konzept/gatsby-source-storyblok/src/components/StoryblokPreview/index.js" /* webpackChunkName: "component---node-modules-sb-konzept-gatsby-source-storyblok-src-components-storyblok-preview-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-photo-resizer-js": () => import("./../../../src/pages/photo-resizer.js" /* webpackChunkName: "component---src-pages-photo-resizer-js" */),
  "component---src-templates-dojo-berichte-js": () => import("./../../../src/templates/dojoBerichte.js" /* webpackChunkName: "component---src-templates-dojo-berichte-js" */),
  "component---src-templates-dojo-dantraeger-js": () => import("./../../../src/templates/dojoDantraeger.js" /* webpackChunkName: "component---src-templates-dojo-dantraeger-js" */),
  "component---src-templates-dojo-gallery-js": () => import("./../../../src/templates/dojoGallery.js" /* webpackChunkName: "component---src-templates-dojo-gallery-js" */),
  "component---src-templates-dojo-homepage-js": () => import("./../../../src/templates/dojoHomepage.js" /* webpackChunkName: "component---src-templates-dojo-homepage-js" */),
  "component---src-templates-dojo-termine-js": () => import("./../../../src/templates/dojoTermine.js" /* webpackChunkName: "component---src-templates-dojo-termine-js" */),
  "component---src-templates-dojo-unterrichtsplan-js": () => import("./../../../src/templates/dojoUnterrichtsplan.js" /* webpackChunkName: "component---src-templates-dojo-unterrichtsplan-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

