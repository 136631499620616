/* eslint-disable global-require */
import { ComponentRegistry } from '@sb-konzept/gatsby-source-storyblok'
import React from 'react'

const registry = new ComponentRegistry()

registry.configure({
  components: {
    grid: React.lazy(() => import('./Grid')),
    button: React.lazy(() => import('./Button')),
    carousel: React.lazy(() => import('./Carousel')),
    header: React.lazy(() => import('./Header')),
    table: React.lazy(() => import('./Table')),
    footer: require('./Footer').default,
    'navigation-header': {
      component: require('./NavigationHeader').default,
      editable: React.lazy(() => import('./NavigationHeader/editable'))
    },
    collapsible: {
      component: React.lazy(() => import('./Collapsible'))
    },
    tabs: {
      component: React.lazy(() => import('./Tabs')),
      editable: React.lazy(() => import('./Tabs/editable'))
    },
    box: {
      component: React.lazy(() => import('./Box')),
      editable: React.lazy(() => import('./Box/editable'))
    },
    container: {
      component: React.lazy(() => import('./Container')),
      editable: React.lazy(() => import('./Container/editable'))
    },
    'rich-text': {
      component: React.lazy(() => import('./RichText')),
      editable: React.lazy(() => import('./RichText/editable'))
    },
    'sliced-header': {
      component: React.lazy(() => import('./SlicedHeader')),
      editable: React.lazy(() => import('./SlicedHeader/editable'))
    },
    slide: {
      component: React.lazy(() => import('./SlicedHeader')),
      editable: React.lazy(() => import('./SlicedHeader/editable'))
    },
    'thick-thin-title': {
      component: React.lazy(() => import('./ThickThinTitle')),
      editable: React.lazy(() => import('./ThickThinTitle/editable'))
    },
    subtitle: {
      component: React.lazy(() => import('./Subtitle')),
      editable: React.lazy(() => import('./Subtitle/editable'))
    },
    'dojo-site-info': {
      component: React.lazy(() => import('./DojoSiteInfo')),
      editable: React.lazy(() => import('./DojoSiteInfo/editable'))
    },
    'dojo-homepage': {
      component: React.lazy(() => import('./DojoHomepage')),
      editable: React.lazy(() => import('./DojoHomepage/editable'))
    },
    'dojo-unterrichtsplan': {
      component: React.lazy(() => import('./DojoUnterrichtsplan')),
      editable: React.lazy(() => import('./DojoUnterrichtsplan/editable'))
    },
    'dojo-gallery': {
      component: React.lazy(() => import('./DojoGallery')),
      editable: React.lazy(() => import('./DojoGallery/editable'))
    },
    'dojo-termine': {
      component: React.lazy(() => import('./DojoTermine')),
      editable: React.lazy(() => import('./DojoTermine/editable'))
    },
    'dojo-berichte': {
      component: React.lazy(() => import('./DojoBerichte')),
      editable: React.lazy(() => import('./DojoBerichte/editable'))
    },
    'dojo-dantraeger': {
      component: React.lazy(() => import('./DojoDantraeger')),
      editable: React.lazy(() => import('./DojoDantraeger/editable'))
    },
    berichte: {
      component: React.lazy(() => import('./Berichte')),
      editable: React.lazy(() => import('./Berichte/editable'))
    },
    'dantraeger-block': {
      component: React.lazy(() => import('./DantraegerBlock')),
      editable: React.lazy(() => import('./DantraegerBlock/editable'))
    },
    'banner-component': {
      component: React.lazy(() => import('./BannerComponent')),
      editable: React.lazy(() => import('./BannerComponent/editable'))
    },
    'banner-story': {
      component: React.lazy(() => import('./BannerStory')),
      editable: React.lazy(() => import('./BannerStory/editable'))
    },
    'home-block': {
      component: React.lazy(() => import('./HomeBlock')),
      editable: React.lazy(() => import('./HomeBlock/editable'))
    },
    'home-collapsible': {
      component: React.lazy(() => import('./HomeCollapsible')),
      editable: React.lazy(() => import('./HomeCollapsible/editable'))
    },
    'news-block': {
      component: React.lazy(() => import('./NewsBlock')),
      editable: React.lazy(() => import('./NewsBlock/editable'))
    },
    'news-entry': {
      component: React.lazy(() => import('./NewsEntry')),
      editable: React.lazy(() => import('./NewsEntry/editable'))
    },
    'termin-container': {
      component: React.lazy(() => import('./TerminContainer')),
      editable: React.lazy(() => import('./TerminContainer/editable'))
    },
    termin: {
      component: React.lazy(() => import('./Termin')),
      editable: React.lazy(() => import('./Termin/editable'))
    },
    'masonry-grid': {
      component: React.lazy(() => import('./MasonryGrid')),
      editable: React.lazy(() => import('./MasonryGrid/editable'))
    },
    'masonry-item': {
      component: React.lazy(() => import('./MasonryItem')),
      editable: React.lazy(() => import('./MasonryItem/editable'))
    },
    'news-image-block': {
      component: React.lazy(() => import('./NewsImageBlock')),
      editable: React.lazy(() => import('./NewsImageBlock/editable'))
    },
    'news-image': {
      component: React.lazy(() => import('./NewsImage')),
      editable: React.lazy(() => import('./NewsImage/editable'))
    },
    badge: {
      component: React.lazy(() => import('./Badge')),
      editable: React.lazy(() => import('./Badge/editable'))
    },
    image: {
      component: React.lazy(() => import('./Image')),
      editable: React.lazy(() => import('./Image/editable'))
    },
    video: {
      component: React.lazy(() => import('./Video')),
      editable: React.lazy(() => import('./Video/editable'))
    },
    'trapezoid-title-block': {
      component: React.lazy(() => import('./TrapezoidTitleBlock')),
      editable: React.lazy(() => import('./TrapezoidTitleBlock/editable'))
    },
    'contact-form': {
      component: React.lazy(() => import('./ContactForm')),
      editable: React.lazy(() => import('./ContactForm/editable'))
    },
    'photo-card': {
      component: React.lazy(() => import('./PhotoCard')),
      editable: React.lazy(() => import('./PhotoCard/editable'))
    },
    'slider-image': {
      component: React.lazy(() => import('./SliderImage')),
      editable: React.lazy(() => import('./SliderImage/editable'))
    }
  },
  previews: {
    page: React.lazy(() => import('./previews/Page')),
    layout: React.lazy(() => import('./previews/Layout')),
    'news-entry': React.lazy(() => import('./previews/newsEntry')),
    'banner-story': React.lazy(() => import('./previews/bannerStory')),
    'dojo-page': React.lazy(() => import('./previews/dojoPage')),
    'dojo-site-info': React.lazy(() => import('./previews/newsEntry')),
    'dojo-homepage': React.lazy(() => import('./previews/dojoHomepage')),
    'dojo-unterrichtsplan': React.lazy(() =>
      import('./previews/dojoUnterrichtsplan')
    ),
    'dojo-gallery': React.lazy(() => import('./previews/dojoPage')),
    'dojo-dantraeger': React.lazy(() => import('./previews/dojoPage')),
    'dojo-termine': React.lazy(() => import('./previews/dojoPage')),
    'dojo-berichte': React.lazy(() => import('./previews/dojoPage'))
  }
})

export default registry
