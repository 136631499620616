import React from "react";
import Link from "components/Link";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";

import { useStoryblokEditable } from "@sb-konzept/gatsby-source-storyblok";

// TODO: Fix this ugly theme hack!
// const useStyles = makeStyles(theme => ({
//   withBgcolor: {
//     backgroundColor: props => {
//       const path = props.content.backgroundColor.split(".");
//       return theme.palette[path[0]][path[1]];
//     }
//   }
// }));

function StoryblokButton(props) {
  // const classes = useStyles(props);
  const { content } = props;
  const { editableProps } = useStoryblokEditable();
  const { color, size, variant, fullWidth, link, backgroundColor } = content;

  // console.log("BUTTON", props);

  return (
    <Button
      color={color}
      size={size}
      variant={variant}
      fullWidth={fullWidth}
      // className={classes.withBgcolor}
      {...link?.cached_url && { component: Link, link }}
      {...editableProps}
    >
      {content.label}
    </Button>
  );
}

export default StoryblokButton;
