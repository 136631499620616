import styled from 'styled-components'

import borders from '@material-ui/system/borders'
import breakpoints from '@material-ui/system/breakpoints'
import compose from '@material-ui/system/compose'
import display from '@material-ui/system/display'
import flexbox from '@material-ui/system/flexbox'
import positions from '@material-ui/system/positions'
import shadows from '@material-ui/system/shadows'
import sizing from '@material-ui/system/sizing'
import spacing from '@material-ui/system/spacing'
import typography from '@material-ui/system/typography'
import style from '@material-ui/system/style'

// we want palette to set 'background' not 'backgroundColor'
// import palette from '@material-ui/system/palette';
export const color = style({
  prop: 'color',
  themeKey: 'palette'
})

export const bgcolor = style({
  prop: 'bgcolor',
  cssProperty: 'background',
  themeKey: 'palette'
})

export const opacity = style({
  prop: 'opacity'
})

export const columnCount = style({
  prop: 'columnCount'
})

export const container = props =>
  style({
    prop: 'container',
    cssProperty: false,
    transform: (isContainer) => {
      const {
        theme: { breakpoints: bp }
      } = props
      if (!isContainer) return {}
      const md = bp.width('md')
      const lg = bp.width('lg')
      const xl = bp.width('xl')
      const a = {
        [bp.up(md + 5)]: {
          maxWidth: md
        },
        [bp.up(lg + 5)]: {
          maxWidth: lg
        },
        [bp.up(xl + 5)]: {
          maxWidth: xl
        },
        marginLeft: 'auto',
        marginRight: 'auto'
      }
      return a
    }
  })(props)

const palette = compose(
  color,
  bgcolor
)

export const styleFunction = breakpoints(
  compose(
    borders,
    display,
    flexbox,
    positions,
    palette,
    shadows,
    sizing,
    spacing,
    typography,
    opacity,
    columnCount,
    container
  )
)

const Background = styled.div`
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Box = styled.div`
  ${styleFunction};

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    width: 6px !important;
    height: 6px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    /*background: rgba(255, 255, 255, 0.08);*/
    background: transparent;
  }
`

Box.Background = Background

export default Box
