/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'typeface-open-sans'
import 'App.css'

import CssBaseline from '@material-ui/core/CssBaseline'
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/styles'
// import { FirebaseProvider, UserProvider } from '@sb-konzept/firebase-hooks'
import { StoryblokComponentProvider } from '@sb-konzept/gatsby-source-storyblok'
import Sidebar from 'components/Sidebar'
import { create } from 'jss'
import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import theme from 'theme'

// import firebaseApp from './src/components/FirebaseWrapper'
import Layout from './src/components/PageLayout'

const imperativePages = [
  '/preview',
  '/404',
  '/admin/photo-resizer',
  '/admin/video-uploader'
]

const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point'
})

// Add the JSS insertion point comment to the top of the head.
export const onClientEntry = () => {
  const styleNode = window.document.createComment('jss-insertion-point')
  window.document.head.insertBefore(styleNode, window.document.head.firstChild)
}

// Remove the server-side injected CSS.
export const onInitialClientRender = () => {
  const ssStyles = window.document.getElementById('server-side-jss')
  return ssStyles && ssStyles.parentNode.removeChild(ssStyles)
}

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: false,
  productionPrefix: 'c'
})

export const wrapRootElement = ({ element }) => (
  // <FirebaseProvider app={firebaseApp}>
  <StylesProvider generateClassName={generateClassName} jss={jss}>
    <StyledThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StoryblokComponentProvider
          registry={
            require('storyblok-components') /* eslint-disable-line global-require */
              .default
          }
        >
          <CssBaseline />
          {/* <UserProvider> */}
          {element}
          {/* </UserProvider> */}
        </StoryblokComponentProvider>
      </ThemeProvider>
    </StyledThemeProvider>
  </StylesProvider>
  // </FirebaseProvider>
)

// Pagecontext could go here with the page data
export const wrapPageElement = ({ element, props }) => {
  const { uri, data } = props
  // console.log("wrapPageElement props", props);
  return (
    <Layout {...props}>
      {!imperativePages.includes(uri) && (
        <Helmet
          defaultTitle="TWIN-Taekwondo"
          defer={false}
          htmlAttributes={{ lang: 'de' }}
          title={data?.page?.content?.title || 'TWIN-Taekwondo'}
        >
          {/* Robot data => exclude pages with boolean true */}
          {data?.page?.content?.robotsIgnore ? (
            <meta content="noindex,nofollow" name="robots" />
          ) : (
            <meta content="index,follow" name="robots" />
          )}
          <meta
            content={data?.page?.content?.metaDescription || 'TWIN-Taekwondo'}
            name="description"
          />
        </Helmet>
      )}
      {!imperativePages.includes(uri) && <Sidebar />}
      {element}
    </Layout>
  )
}
