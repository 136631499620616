import React from "react";
import { Link } from "gatsby";
// import { makeStyles } from "@material-ui/core/styles";
// import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";

// const useStyles = makeStyles(theme => ({
//   paper: {
//     position: "absolute",
//     left: 0,
//     right: 0,
//     top: "10%",
//     bottom: "10%",
//     overflowY: "scroll",
//     margin: "auto",
//     maxWidth: 800,
//     backgroundColor: theme.palette.primary.main,
//     border: "2px solid #000",
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 4),
//     outline: "none",
//     textAlign: "center"
//   },
//   list: {
//     margin: "auto",
//     maxWidth: "60%"
//   }
// }));

export default function DatenschutzModal() {
  // const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <Box display="inline" mt="0.8rem">
      <Box display="inline" fontSize="0.75rem" color="text.secondary">
        Hiermit stimme ich den{" "}
        <Box
          // onClick={handleOpen}
          component={Link}
          to="/impressum#datenschutz"
          display="inline"
          color="primary.main"
          style={{ cursor: "pointer", textDecoration: "none" }}
        >
          Datenschutz-Bedingungen
        </Box>{" "}
        von TWIN Taekwondo zu
      </Box>
      {/* <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.paper}>
          <h2 id="modal-title">TWIN Taekwondo Datenschutz-Bedingungen</h2>
          <p id="simple-modal-description">
            Bei der Bestellung geben Sie Ihre personenbezogene Daten an.
            <br />
            <br />
            Es werden folgende Daten gesammelt und gespeichert:
          </p>
          <br />
          <br />
          <ul className={classes.list}>
            <li>Name.</li>
            <li>E-Mail.</li>
            <li>Abholpunkt.</li>
            <li>Flugnummer.</li>
          </ul>
          <br />
          <br />
          <p id="simple-modal-description">
            Die Informationen werden für folgende Zwecke erhoben:
          </p>
          <br />
          <br />
          <ul className={classes.list}>
            <li>Transportdienstleistung.</li>
            <li>Beratung in Bezug auf Ausbau der Mobilitäts-Services.</li>
            <li>Entwicklung softwarebasierter Dienste für Fahrgäste.</li>
            <li>
              Marktforschungszwecke u.a. Gestaltung der Angebote (Produkte und
              Dienstleistungen).
            </li>
          </ul>
          <br />
          <br />
          <p id="simple-modal-description">
            Sie sind gemäß der Datenschutz-Grundverordnung (DSGVO) jederzeit der
            umfangreichen Auskunftserteilung zu den zu Ihrer Person
            gespeicherten Daten berechtigt.
            <br />
            <br />
            Gemäß des DSGVO, können Sie jederzeit die Berichtigung, Löschung und
            Sperrung einzelner personenbezogener Daten verlangen.
            <br />
            <br />
            Sie können darüber hinaus jederzeit ohne Angabe von Gründen von
            Ihrem Widerspruchsrecht Gebrauch machen und die erteilte
            Einwilligungserklärung mit Wirkung für die Zukunft abändern oder
            gänzlich widerrufen. Sie können den Widerruf per E-Mail übermitteln
            (E-Mail: muenchen@twintaekwondo.com). Es entstehen Ihnen dabei keine
            Kosten.
          </p>
        </Box>
      </Modal> */}
    </Box>
  );
}
