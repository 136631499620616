const {
  // responsiveFontSizes,
  createMuiTheme
} = require('@material-ui/core/styles')

const defaultTheme = createMuiTheme()

// console.log("defaultTheme: ", defaultTheme);

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffd100'
    },
    secondary: {
      main: '#ffffff',
      light: '#f7f7f7'
    },
    tertiary: {
      main: '#000000'
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
      inactive: '#7c7c7c',
      active: '#000000'
    },
    background: '#eaeaea',
    gradient: {
      light: '#00adb0',
      main: '#00b7ff',
      dark: '#0077f1',
      contrastText: '#ffffff'
    }
  },
  shape: { borderRadius: 0 },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif'
    ].join(','),
    useNextVariants: true
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontWeight: 700,
        fontFamily: 'futura-pt',
        fontSize: '5rem', // 6rem
        [defaultTheme.breakpoints.only('sm')]: {
          fontSize: '4rem'
        },
        [defaultTheme.breakpoints.only('xs')]: {
          fontSize: '2.8rem'
        }
      },
      h2: {
        fontWeight: 700,
        fontFamily: 'futura-pt',
        fontSize: '3.75rem',

        [defaultTheme.breakpoints.only('sm')]: {
          fontSize: '2.5rem'
        },
        [defaultTheme.breakpoints.only('xs')]: {
          fontSize: '1.875rem'
        }
      },
      h3: {
        fontWeight: 700,
        fontFamily: 'futura-pt',
        fontSize: '2.45rem',

        [defaultTheme.breakpoints.only('sm')]: {
          fontSize: '1.633rem'
        },
        [defaultTheme.breakpoints.only('xs')]: {
          fontSize: '1.4rem'
        }
      },
      h4: {
        fontWeight: 700,
        fontFamily: 'futura-pt',
        fontSize: '1.8rem',

        [defaultTheme.breakpoints.only('sm')]: {
          fontSize: '1.5rem'
        },
        [defaultTheme.breakpoints.only('xs')]: {
          fontSize: '1.33rem' // 1.33rem
        }
      },
      h5: {
        fontWeight: 300,
        fontFamily: "futura-pt, 'Open Sans'",
        fontSize: '2.2rem'
        // marginBottom: "-1.2rem"
      },
      h6: { fontWeight: 700, fontFamily: 'futura-pt', fontSize: '1.5rem' },
      body1: {
        fontWeight: 400,
        // fontFamily: "'Open Sans', sans-serif",
        fontSize: '1rem',
        [defaultTheme.breakpoints.up('xl')]: {
          fontSize: '1.33rem'
        }
        // [defaultTheme.breakpoints.only("sm")]: {
        //   fontSize: "1.5rem"
        // },
      },
      button: { fontWeight: 700 },
      subtitle2: {
        fontWeight: 500,
        textTransform: 'none',
        marginTop: '1rem'
      }
    },
    MuiDivider: {
      root: {
        marginBottom: 16
      }
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: '#ffffff',
        margin: 0,
        boxShadow: defaultTheme.shadows[4],

        '&$expanded': {
          margin: 0
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        alignItems: 'flex-start',

        '&$focused': {
          backgroundColor: '#ffffff'
        }
      },
      content: {
        alignItems: 'center',
        fontFamily: 'Open sans, sans-serif',
        padding: 0,
        margin: 0,

        '&$expanded': {
          margin: 0
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: '#ffffff',
        fontFamily: "'Open Sans', sans-serif",
        padding: 24,
        paddingBottom: 8
      }
    },
    MuiCard: {
      root: {
        backgroundColor: '#ffffff',
        height: '100%',
        boxShadow: defaultTheme.shadows[4],
        fontFamily: "'Open Sans', sans-serif"
      }
    },
    MuiCardContent: {
      root: {
        '&:last-child': {
          paddingBottom: 'initial' // 16
        }
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minWidth: 120,
        fontSize: 'initial'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiTable: {
      root: {
        width: '100%',
        margin: 'auto 0'
      }
    },
    MuiTableCell: {
      root: {
        textAlign: 'center',
        color: 'inherit',

        '&:last-child': {
          paddingRight: '6px'
        }
      },
      head: {
        fontWeight: 500,
        border: 'none'
      }
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        textTransform: 'none',

        '&$disabled': {
          color: 'inherit'
        }
      },
      textPrimary: {
        color: 'black',
        backgroundColor: '#ffd100',

        '&:hover': {
          color: 'black',
          backgroundColor: '#ffd100dd'
        }
      },
      textSecondary: {
        color: 'white',
        backgroundColor: 'black',

        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.85)'
        }
      },
      outlined: {
        border: '2px solid currentColor'
      }
    },
    MuiDrawer: {
      paper: {
        background: 'white'
      }
    }
    // MuiMenuItem: {
    //   root: {
    //     background: "white",
    //     "&$selected": {
    //       // <-- mixing the two classes
    //       backgroundColor: "white",
    //       "&:hover": {
    //         backgroundColor: "white"
    //       }
    //     }
    //   }
    // }
  }
})

// TODO: Is there a better way to do this? ie => setting inside the theme as 'theme: { shadows: { [...shadows, newValue ]} }' ? ? ? ?
muiTheme.shadows[24] = '8px 4px 4px 0px rgba(0, 0, 0, 0.1)'

// let theme = responsiveFontSizes(muiTheme);
// module.exports = theme;

module.exports = muiTheme
